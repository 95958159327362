<template>
  <div class="disclaimer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center">Disclaimer</h1>
          <p style="margin-top:30px;">
            CherryFrost would like you to understand how much we value and care
            about your orders. Please go through our disclaimer to understand
            our service policies.
          </p>
          <h2>General Disclaimer</h2>
          <p>
            Cherryfrost has the right to cancel your order unconditionally if
            certain circumstances arise.
          </p>          
          <h2>Shipping and Delivery Disclaimer</h2>
          <p>
            Currently Cherryfrost is providing delivery only in certain
            localities of Guwahati. If your location is servicable, it will show up when you enter your locality.
            <!-- A full list of available delivery locations
            can be found here - <strong><router-link to="/available-locations">Available Locations</router-link></strong> -->
          </p>
          <p>
            Cherryfrost may either use its own delivery personnel to deliver
            your order or use other partnered delivery networks in order to
            maintain optimal delivery service.
          </p>
          <p>
            In case we use any third-party delivery network to deliver your
            order, we will notify the customer beforehand about the delivery
            partner and other related information.
          </p>
          <p>
            Deliveries may not always complete on the customer selected time
            slot. Possible reasons:
          </p>
          <ul>
            <li>Bad weather condition</li>
            <li>Political disruptions</li>
            <li>Other foreseen circumstances.</li>
          </ul>
          <p>
            In such cases, Cherryfrost will reach out to you through email or
            call. We recommend checking your emails to get all updates regarding
            your delivery.
          </p>
          <p>
            Since the product is perishable, we attempt to deliver your product
            only once. If the product does not get delivered due to customer
            fault, Cherryfrost will not refund or re-deliver your order. We will
            consider the order not executed due to:
          </p>
          <ol>
            <li>Wrong shipping/delivery address</li>
            <li>Recipient not available</li>
            <li>Recipient refuse to accept the delivery</li>
            <li>Premises locked</li>
          </ol>
          <p>
            If the recipient is not available, he/she has the option to inform
            the delivery person to deliver the order to the gate/reception or
            neighbour. This would be made on a best effort basis and we will not
            be liable for any tampering with the package once delivery has been
            made.
          </p>
          <p>
            Customers have to provide proof of delivery/address in case of
            dispute in delivery.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>	

	export default {		
		mounted() {
      this.$hideLoading()
		}
	}
</script>

<style scoped>
ul>li {
  /* display: inline-block; */
  margin-bottom: 10px;
}
</style>