<template>
  <div class="shipping">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center">Shipping</h1>
          <ol style="margin-top:30px;">
            <li>
              Cherryfrost may either use its own delivery personnel to deliver
              your order or use other partnered delivery networks in order to
              maintain optimal delivery service.
            </li>
            <li>
              If we use a third-party delivery network, we will notify you
              regarding the use of third party service  the product is out
              for delivery.
            </li>
            <li>
              Cherryfrost will not be responsible for any issues caused by the
              third-party delivery network while delivering your orders.
            </li>
            <li>
              If you face any issues with the order or the product you can
              contact us through our contact details in our contact us page.
            </li>
            <li>Delivery times may take longer due to:</li>
            <ol>
              <li>Bad weather conditions</li>
              <li>Political disruptions</li>
              <li>Other unforeseen circumstances.</li>
              <li style="list-style-type: none;text-indent: -1em;">
                In such cases, Cherryfrost will reach out to you through email or
                call. Please check your emails to get all the updates regarding your
                delivery.
              </li>
            </ol>
          </ol>
          <ol start="6">
            <li>
              Since the products are perishable, we attempt to deliver your
              product only once. If the product does not get delivered due to
              customer fault, cherryfrost will not refund or re-deliver your
              order. We will consider the order not executed due to:
            </li>
            <ol>
              <li>Wrong shipping / delivery address</li>
              <li>Recipient not available</li>
              <li>Recipient refuses to accept the delivery</li>
              <li>Premises locked</li>
            </ol>
          </ol>
          <p>
            More information can be found in shipping and delivery disclaimer
            <strong> <router-link to="/disclaimer#shipping-and-delivery">here</router-link> </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>	

	export default {		
		mounted() {
      this.$hideLoading()
		}
	}
</script>