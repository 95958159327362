<template>
  <div class="home-page-info pb-50">
	  <h1 class="text-center">About Us</h1>
    <div class="container">
      <div class="row">
		  <div class="col-md-12">
			  <div class="info">
					<p>
						Cherryfrost is a Guwahati based online bakery platform. We deliver cakes made by home baker(s) under our direct supervision.
					</p>				  
			  </div>
		  </div>
        <div class="col-md-12">
          <div class="info">
            <h2>CherryFrost: Wishes fulfilled with love</h2>
            <p>
              We deliver cakes for every occassion in guwahati. They are always made fresh after an order is placed. We also support same day delivery if ordered early in the day.
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="info">
            <h2>Made by our Home Bakers</h2>
            <p>
              Nothing matches the taste of cakes by home bakers made with utmost love and care. Every ingredient used is fresh and high quality to make the best tasting cakes.
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="info">
            <h2>Order customized cakes</h2>
            <p>
              We try our best to deliver you with exactly the kind of cake you are looking for. In addition to our diverse catalog of cakes, we also accept orders having design, flavour, theme, etc as per your preference. You can either show us an image or give us and idea and we will bake the perfect cake according to your desires!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>	
	export default {		
		mounted() {
      this.$hideLoading()
		}
	}
</script>

<style scoped>
.info h2{
    font-size: 24px;
    /* color: #C82643; */
    margin-bottom: 10px;
}

.info{
    margin-bottom: 20px;
    padding: 0px 12px;
}

.info p{
  font-size: 18px;
  line-height: 25px;
}

@media(max-widht:768px) {
  .info h2{
      font-size: 18px;
      /* color: #C82643; */
      margin-bottom: 10px;
  }

}

</style>