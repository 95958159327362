<template>
  <div class="payment-wrapper material-card bg-white d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="payment-card">
            <div class="d-flex justify-content-between">
              <span>ORDER #{{ $route.query.oid }}</span>
              <strong>TOTAL : Rs {{ $route.query.total }}  </strong>
            </div>
            <h1>Make Payment</h1>
            <p>You may use Google Pay, Phone Pay, or any other UPI app to pay. You will receive an acknowledgement email from us shortly after payment is completed</p>
            <div>
              <h3 class="payment-title-1">{{ paymentTitle1 }}</h3>
            </div>
            <div class="row">
              <div class="col-md-8">
                <img style="padding:15px;" v-if="$mq !== 'sm'" src="@/assets/QR.png" alt="">
              </div>
              <div class="col-md-4 d-flex align-items-center upi">
                <div ref="upicode">
                  cherryfrost@upi
                </div>
                <!-- <button class="copy-btn" @click="copyUpi()">{{ copyBtnText }}</button> -->
                <button class="copy-btn" @click="copyUpi()">COPY</button>
              </div>
            </div>
             <div>
              <h3 class="query-title">HAVE ANY QUERIES?</h3>
            </div>
            <p>For alternate payment methods or any other queries, call/whatsapp us at +91-7578025236</p>
          </div>
        </div>
        <div class="col-6 col-md-2 offset-md-4">
          <a href="tel:6001736718" data-rel="external" class="cta-btn call">
            CALL
          </a>
        </div>
        <div class="col-6 col-md-4">
          <a href="https://wa.me/+917578025236" class="cta-btn whatsapp" target="_blank">
            WHATSAPP
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({  
    copyBtnText: 'copy'  
  }),
  mounted() {
    this.$hideLoading()
  },  
  computed: {
    paymentTitle1() {
      return  this.$mq == 'sm'? "PAY USING OUR UPI ID" : "SCAN QR OR USE OUR UPI ID"
    }
  },
  methods:{
    copyUpi(){
      const text = this.$refs.upicode.innerText;
      navigator.clipboard.writeText(text)
      this.copyBtnText = 'Copied'
      this.$toasted.show(
        "UPI ID copied successfully <i class='icon-ok-circle text-success'></i>"
      );      
    }
  }
}
</script>

<style scoped>
.payment-wrapper{
  max-width: 700px;
  /* background: gainsboro; */
  margin: 0 auto;
  min-height: 75vh;
  padding:40px;
  margin-top: 40px;
}

.payment-card{
  background: white;
  
}

.payment-card p {
  font-size: 16px;
  color: #777;  
  margin-top: 30px;
}

.payment-title-1 {
  margin-top: 30px;
}

.query-title {
  margin-top: 30px;
}

.cta-btn{
  padding: 8px 25px;
  width: 100%;
  letter-spacing: 4px;
  display: block;
  text-align: center;
}

.call{
  margin-right: 5px;
  background: white;
  border: 1px solid black;
}

.whatsapp{
  margin-left: 5px;
  border: 1px solid transparent;
  background: #f2f2f2;
}


.copy-btn{
  border: 1px solid black;
  background: white;
  letter-spacing: 1px;
  padding:4px 15px;
  margin-left: 15px;
  cursor: pointer;
}

.upi {
  flex-direction: column;
  justify-content: center;
}

.upi>div{
  background:#eee;
  display: inline-block;
  padding: 6px 15px;  
  margin-bottom: 15px;
}

@media(max-width: 1366px) {
  .payment-wrapper{
    min-height: 100vh;
    padding: 60px 0px;
    }
}


@media(max-width: 767px) {
  .payment-wrapper {
    margin-top: 20px;
    min-height: 70vh;
    padding: 40px 20px;
  }

  .upi {
    flex-direction: row;
    justify-content: flex-start;
  }  

  .upi>div {
    margin-bottom: 0;
  }

  .cta-btn{

  }
}

</style>