<template>
  <div class="terms-and-conditions">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center">Terms and Conditions</h1>
          <p>
            Please read the terms and conditions carefully as the use of the
            service is subjected to your acceptance of the following terms and
            conditions(&ldquo;Terms&rdquo;).
          </p>
          <p>
            By using our service, you agree that you have understood and are
            bound to its terms and conditions, if you do not agree to our terms
            then please do not use our website.
          </p>
          <h2>Introduction</h2>
          <p>
            Cherryfrost.com is an Guwahati based online bakery portal. We
            deliver cakes made by home baker(s) under our supervision. For
            address and contact information please refer to the Contact Us
            section <strong>(<router-link to="/contact-us">here</router-link>)</strong>
          </p>
          <h2>Definition</h2>
          <ul>
            <li>
              &ldquo;Agreement&rdquo; refers to the terms and conditions of the
              website
            </li>
            <li>
              &ldquo;Privacy Policy&rdquo; means the policy that we use in our
              website to protect customers' personal information.
            </li>
            <li>
              &ldquo;You&rdquo;,&rdquo;your&rdquo;,&rdquo;yours&rdquo; refers to
              the user or the customer using the website or service.
            </li>
            <li>
              &ldquo;We&lsquo;,&rdquo;us&rdquo;,&rdquo;our &rdquo; refers to
              Cherryfrost.com
            </li>
            <li>
              &ldquo;Service&rdquo; or &ldquo;service&rdquo; refers to any goods
              which we provide through our website
            </li>
            <li>
              &ldquo;Item&rdquo; refers to the goods which are available for
              purchase.
            </li>
            <li>
              &ldquo;Food Delivery&rdquo; refers to the delivery of products by
              us or our partners.
            </li>
          </ul>
          <h2>Ordering</h2>
          <ol>
            <li>
              Delivery service for orders placed from this website are
              either directly between you and Cherryfrost or between you and any
              of our partner food delivery networks. For any food or product
              related services from this website, contact is strictly between
              you and cherryfrost.
            </li>
            <li>
              Care is advised while providing your personal and address
              information so that the delivery process can be smooth and
              optimal.
            </li>
            <li>
              Items purchased from this website are only for personal use and
              not allowed for any kind of resale from your end.
            </li>
            <li>
              Ordering from this website will require you to provide your
              contact number, email address, and your home address.
            </li>
            <li>
              We take full responsibility to keep your information safe and
              secure. But in the absence of negligence on your part we cannot be
              held responsible for any loss you may suffer.
            </li>
            <li>
              All financial transactions are processed through a payment gateway
              provider and are not stored or processed through our servers.
            </li>
            <li>
              Any order that you place on our website is subject to product
              availability and acceptance by us. We will notify you through the
              website as well as email when we confirm your order. You must
              inform us immediately if you find any details incorrect in the
              email.
            </li>
            <li>
              Once the order is ready to be delivered, we will use our own
              delivery service or any other third-party delivery service to
              deliver your order to the shipping address you provided. In case
              of third-party delivery service, we will notify you through email
              once the order is out for delivery.
            </li>
            <li>
              If you face any issues with the third party delivery service,
              Cherryfrost will not be responsible for that. You will have to
              contact the delivery network in question for your queries.
            </li>
            <li>
              If an item is not available or a lack of delivery capacity by us
              then we will contact you via email, text message and/or phone
              call. In this case, we may suggest you with an alternate item
              and/or delivery slot. You would also have an option for a full
              refund.
            </li>
          </ol>
          <h2>Prices and Payment</h2>
          <ol>
            <li>
              All financial transactions are processed through a payment gateway
              provider and are not stored or processed through our servers. For
              privacy policy and terms and condition information, please refer
              to the relevant payment gateway used while placing the order.
            </li>
            <li>
              The total price of the item(s), including delivery charges and
              other charges will be displayed on the website at the time of your
              purchase. You would also receive an email regarding the same after
              an order is placed.
            </li>
            <li>
              We reserve the right to alter the prices of items on the website
              in future.
            </li>
            <li>
              We will not be responsible if you get into any issues during
              payment with the third-party payment gateway service.
            </li>
            <li>
              We will refund your payment in case there is an unavailability of
              the product after you have placed the order or during the time of
              cancellation of the order.
            </li>
          </ol>
          <h2>Delivery</h2>
          <p>
            If the recipient is not available, he/she has the option to inform
            the delivery person to deliver the order to the gate/reception or
            neighbour. This would be made on a best effort basis and we will not
            be liable for any tampering with the package once delivery has been
            made.
          </p>
          <p>
            Customers have to provide proof of delivery/address in case of
            dispute in delivery.
          </p>
          <p>
            Cherryfrost may either use its own delivery personnel to deliver
            your order or use other partnered delivery networks in order to
            maintain optimal delivery service.
          </p>
          <p>
            In case we use any third-party delivery network to deliver your
            order, we will notify the customer beforehand about the delivery
            partner and other related information.
          </p>
          <p>
            Deliveries may not always complete on the customer selected time
            slot. Possible reasons:
          </p>
          <ul>
            <li>Bad weather condition</li>
            <li>Political disruptions</li>
            <li>Other foreseen circumstances.</li>
          </ul>
          <p>
            In such cases, Cherryfrost will reach out to you through email or
            call. We recommend checking your emails to get all updates regarding
            your delivery.
          </p>
          <p>
            Since the product is perishable, we attempt to deliver your product
            only once. If the product does not get delivered due to customer
            fault, Cherryfrost will not refund or re-deliver your order. We will
            consider the order not executed due to:
          </p>
          <ol>
            <li>Wrong shipping/delivery address</li>
            <li>Recipient not available</li>
            <li>Recipient refuse to accept the delivery</li>
            <li>Premises locked</li>
          </ol>
          <h2>Cancellation</h2>
          <p>Please refer to Cancellation and Refund page on our website</p>
          <h2>Linked Sites</h2>
          <p>
            We do not provide any link to any third-party website from our
            website.
          </p>
          <h2>Complaints</h2>
          <ol>
            <li>
              We take any complaints very seriously and try to solve the issue
              within 24 business hours. You may send your complaint related
              queries to cherryfrostindia@gmail.com and our team will assist you
              promptly.
            </li>
            <li>
              Note: the images displayed on our website are indicative in nature
              and the actual product may vary in shape and design depending on
              the availability.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>	

	export default {		
		mounted() {
      this.$hideLoading()
		}
	}
</script>

<style scoped>
ul>li {
  /* display: inline-block; */
  margin-bottom: 10px;
}
</style>