<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-md-12 mt-md-5">
					<div class="text-center">
						<h1>Contact Us</h1>
						<h4 style="text-decoration:underline;">Address and contact</h4>
						<p>Ground Floor, Bakulban Housing Colony, Bhetapara Road, Hatigaon, Guwahati - 781038</p>
						<p>Tel : +91-8638122677, +91-7577209273</p>
						<p>Email: cherryfrostindia@gmail.com</p>
					</div>
				</div>
			</div>			
			<div class="row">
				<div class="col-12">
					<h2 class="text-center">Send us a message</h2>
					<div class="material-card bg-white" style="padding-bottom:5px;">
						<form @submit.prevent="handleContact">
						<div style="padding:10px 15px;">
							<div class="pt-10">
								<label for="">Enter Your Full Name</label>
								<div class="pt-10">
									<input v-model="fullName" type="text" required>
								</div>
							</div>
							<div  class="pt-10">
								<label for="">Enter Your Email</label>
								<div class="pt-10">
									<input v-model="email" type="email" required>						
								</div>
							</div>
							<div class="pt-10">
								<label for="">Mobile</label>
								<div class="pt-10">
									<input
										placeholder="Optional"
										type="text"
										style="width:100%;"
										v-model.number="phone"
										pattern="[0-9]*" 
										inputmode="numeric"
									/>							
								</div>
							</div>
							<div class="pt-10">
								<label for="">Description</label>
								<div class="pt-10">
									<textarea v-model="description" style="width:100%;" name="" id="" rows="4" required></textarea>
								</div>
							</div>		
							<div class="pt-10">
								<div v-if="submitting">
								<em>Submitting...</em>
								</div>								
								<button v-else type="submit" class="btn-wide btn-primary">
								SUBMIT
								</button>								
							</div>					
						</div>
						</form>
					</div>					
				</div>
			</div>
		</div>

	</div>
</template>

<script>

import {bus} from './../main'
import { db } from "@/database.js"

export default {
	data: () => ({
		submitting: false,
		phone: '',
		fullName: '',
		email: '',
		description: '',
	}),
	mounted(){
		bus.$emit('show_loading', false)
	},
	methods: {
		handleContact() {
		this.submitting = true
		let contact = {
			name: this.fullName,
			phone: this.phone,
			email: this.email,
			description: this.description
		}
		const contactsRef = db.collection("contacts")
		const docId = contactsRef.doc().id
		console.log({
			id: docId,
			...contact
		})
		// return
		contactsRef.doc(docId).set({
			id: docId,
			...contact
		})
		.then(() => {
			this.$toasted.show(
				"Submitted successfully! <i class='icon-ok-circle text-success'></i>"
			);
		})
		.catch(err => {
			console.log(err)
			this.$toasted.error("Something Went Wrong.")
		})
		.finally(() => {
			this.submitting = false
		})
		}
	}
}
</script>

