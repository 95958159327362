<template>
  <div class="cancellation">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center">Cancellation and Refunds</h1>
          <ol style="margin-top:30px;">
            <li>
              All items are made on order, optionally customized as per customer
              needs and also are perishable in nature, therefore refund and
              cancellation is not possible after preparation has started for the
              item.
            </li>
            <li>
              We approve cancellation requests if made within 15 minutes of
              placing the order. However, the customer is free to make a request
              to us even after this period is over but in this case the decision
              to approve or decline the request is completely at the discretion
              of Cherryfrost.
            </li>
            <li>
              Cancellation requests for an order shall be made to
              cherryfrost.com via email at cherryfrostindia@gmail.com stating
              the order id and the reason for the cancellation.
            </li>
            <li>
              Refunds will be issued to the same card/ method or medium through
              which the payment has been made by the customer.
            </li>
            <li>
              All refunds shall be made within a period of 5 - 7 working days
              from the date of cancellation.
            </li>
            <li>
              In the event that the refund could not be successfully processed
              even after the refund period has ended, you would need to inform
              us on our provided email stating the order id and refund amount
              and we will take additional steps from our end to help resolve the
              issue.
            </li>
            <li>
              It is at the discretion of Cherryfrost to change, cancel, refund
              or offer any other option to the customer.
            </li>
            <li>
              If a product is unavailable after the order has been placed, the
              customer will be notified and a full refund will be issued for
              that product.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>	
	export default {		
		mounted() {
      this.$hideLoading()
		}
	}
</script>