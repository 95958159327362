<template>
  <div class="privacy-policy">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 style="text-center"><span style="font-weight: 400;">Privacy Policy</span></h1>
          <p style="margin-top:30px;">
            <span style="font-weight: 400;"
              >Our privacy policy is subjected to change anytime without notice.
              Please check the page occasionally to remain up-to-date with
              it.</span
            >
          </p>
          <p>
            <span style="font-weight: 400;"
              >By visiting the website you agree to&nbsp; accept the term and
              conditions and privacy policy of Cherryfrost. If you do not agree
              with any of the terms please do not access our website.</span
            >
          </p>
          <p>
            <span style="font-weight: 400;"
              >By using our website you accept our use and disclosure of your
              personal information in accordance with our privacy
              policy.&nbsp;</span
            >
          </p>
          <h2>
            <span style="font-weight: 400;"
              >What personal information do we collect from the people who visit
              our website?</span
            >
          </h2>
          <p>
            <span style="font-weight: 400;"
              >When registering or ordering from our website, you may be asked
              to enter your full name, phone number, email address,
              delivery/shipping address and billing details to help you with
              your buying experience.</span
            >
          </p>
          <h2>
            <span style="font-weight: 400;"
              >How do we use your information?</span
            >
          </h2>
          <p>
            <span style="font-weight: 400;"
              >All the information we acquire from you when you register, make a
              purchase, respond to our survey or marketing communications or
              while surfing the website or any other site features are used as
              follows:</span
            >
          </p>
          <ol>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >To personalize your experience to provide you with suggested
                entities that may best align with your interests.</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >To improve the overall website so that we can serve you
                better.</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >To provide our customers with better service as per their
                request.</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >To process your transactions safely and securely.</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >To ask for rating our products and other features in the
                website</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >To send promotional email and messages on your registered phone
                number and/or email.</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >To follow up with our customers through live chats, emails and
                phone numbers.</span
              >
            </li>
          </ol>
          <h2>
            <span style="font-weight: 400;"
              >How do we protect your information?</span
            >
          </h2>
          <p>
            <span style="font-weight: 400;"
              >We regularly check our websites for possible security issues and
              vulnerabilities in order to make our website safe for our
              customers.</span
            >
          </p>
          <p>
            <span style="font-weight: 400;"
              >The user's information is stored securely in reliable and high
              performance servers that can only be accessed by a limited&nbsp;
              set of people who possess special access rights to the system. The
              website is protected via SSL (Secure Socket Layer) technology
              which helps protect and secure your sensitive / credit information
              with the help of encryption.</span
            >
          </p>
          <p>
            <span style="font-weight: 400;"
              >We have also put a variety of security measures in place for when
              a user places an order or enters / submits their information to
              maintain the safety and integrity of that information.</span
            >
          </p>
          <p>
            <span style="font-weight: 400;"
              >All financial transactions are processed through a payment
              gateway provider and are not stored or processed through our
              servers.</span
            >
          </p>
          <h2>
            <span style="font-weight: 400;">Do we use Cookies (and why)?</span>
          </h2>
          <p>
            <span style="font-weight: 400;"
              >Cookies are tiny files stored on your PC or device, which are
              typically used by websites or web apps to tailor your online
              experience. They are confined within the browser scope and would
              not in any way become an independent system resource</span
            ><span style="font-weight: 400;">.</span>
          </p>
          <p>
            <span style="font-weight: 400;"
              >We may need to use cookies in some instances to temporarily save
              user specific information for easy retrieval in the future.</span
            >
          </p>
          <p>
            <span style="font-weight: 400;"
              >However, you have the option to turn off cookies in the browser
              if you choose to do so. Your overall experience may slightly vary
              but core services like viewing products, ordering, etc would be
              unaffected.</span
            >
          </p>
          <h2><span style="font-weight: 400;">Third Party disclosure</span></h2>
          <p>
            <span style="font-weight: 400;"
              >We do not sell, trade or transfer any customers personal details
              to any third-party.</span
            >
          </p>
          <h2><span style="font-weight: 400;">Third Party Links</span></h2>
          <p>
            <span style="font-weight: 400;"
              >All the items present are from Cherryfrost itself and not from
              any third-party.&nbsp;</span
            >
          </p>
          <h2>
            <span style="font-weight: 400;">Your email is collected to</span>
          </h2>
          <ol>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >Send information, respond to your queries or other
                requests.&nbsp;</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >Process your order and give updates pertaining to your
                orders.</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >Send additional information related to products, changes and
                new features .</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >Email you regarding order status and related transaction
                details or receipts.</span
              >
            </li>
            <li style="font-weight: 400;">
              <span style="font-weight: 400;"
                >Occasionally send promotional emails.</span
              >
            </li>
          </ol>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>	
	export default {		
		mounted() {
      this.$hideLoading()
		}
	}
</script>